.el-tab {
  border-top: 6px solid #f0f3fa;

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-tab {
    margin: 6px 16px 6px 0;
    padding: 0;
    font-size: 12px;
  }
  .ant-tabs-top {
    padding: 0 16px;
  }
}

@primary-color: #0268b7;