.order-info-card {
  .ant-card-head {
    padding: 0 16px;
    min-height: 40px;
    color: rgba(0, 0, 0, 0.8);
    // background: #fafafa;
    .ant-card-head-title {
      padding: 8px 0;
      font-weight: bolder;
    }
  }
  .ant-card-body {
    padding: 0;
  }
}

.title-bordered {
  .ant-card-head {
    border: 1px solid #e8e8e8;
    background: #f0f0f0;
  }
}
@primary-color: #0268b7;