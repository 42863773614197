@import '@/style//variable.less';

.footer-location {
  width: 100% !important;
  // height: @footer-height;
  // background: linear-gradient(
  //   158.31deg,
  //   rgba(16, 107, 192, 1) 18.52%,
  //   rgba(14, 101, 180, 0.98) 107.42%,
  //   rgba(0, 101, 195, 0.82) 74.21%
  // );
  background-color: #0268b7;
  .footer-wrapper {
    width: 1200px;
    margin: 0 auto;
    padding-top: 10px;
    color: #fff;

    .footer-wrapper-top {
      width: 100%;
      display: flex;
      height: 70px;
      // line-height: 60px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.35);
      .footer-wrapper-top-content {
        width: 100%;
        display: flex;
        padding: 0 30px;
      }
      .ant-col {
        text-align: right;
      }
      .footer-content-item {
        width: 240px;
        margin-right: 60px;
        text-align: left;
        display: flex;
        align-items: center;
        span {
          display: block;
          line-height: 24px;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    .footer-wrapper-center {
      width: 100%;
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.35);
      .footer-wrapper-center-content {
        width: 100%;
        display: flex;
        padding: 30px 30px;
      }
      .ant-col {
        text-align: left;
        display: flex;
      }
      .footer-center-item {
        width: 240px;
        margin-right: 60px;
        text-align: left;
        .right-title {
          display: inline-block;
          height: 28px;
          line-height: 22px;
        }
        .right-desc {
          font-size: 12px;
          line-height: 28px;
          p {
            line-height: 28px;
          }
        }
      }
      .footer-wrapper-center-right {
        margin-left: 8px;
        .right-title {
          display: inline-block;
          height: 28px;
          line-height: 22px;
        }
        .right-desc {
          font-size: 12px;
          line-height: 28px;
        }
      }
      .footer-wrapper-center-center {
        display: flex;
        flex-direction: column;
        .center-title {
          display: inline-block;
          height: 28px;
          line-height: 22px;
        }
        .right-desc {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          line-height: 34px;
        }
      }
    }
    .footer-wrapper-bottom {
      height: 60px;
      line-height: 60px;
      font-size: 12px;
    }
  }
}

@primary-color: #0268b7;