// write the style validate of full project
body {
  // overflow-x: hidden;
  font-family: @font-family;
  #root {
    width: 100vw; // calc(100vw + 24px);
    overflow-x: auto;
  }
}

.x-fill-100 {
  width: 100%;
}

.x-fill-100vw {
  width: 100vw;
}

.y-fill-100 {
  height: 100%;
}

.y-fill-100vh {
  height: 100vh;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.display-block {
  display: block;
}

.hidden {
  display: none;
}

.hidden-i {
  display: none !important;
}

.ellipsis-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: initial;
  overflow: hidden;
}

.ellipsis-line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: initial;
  overflow: hidden;
}

// use for red color text, like '特'
.text-red,
.text-error {
  color: @error-color;
}

.text-error-f {
  color: @error-color !important;
}

.text-primary {
  color: @primary-color;
}

.text-primary-f {
  color: @primary-color !important;
}

.text-default {
  color: @default-color;
}

.text-info {
  color: @info-color;
}

.text-info-f {
  color: @info-color !important;
}

.text-success {
  color: @success-color;
}

.text-success-f {
  color: @success-color !important;
}

.text-bolder {
  font-weight: bolder;
}

.text-link {
  .text-info;
  cursor: pointer;

  &[disabled] {
    color: @disabled-color;
    cursor: not-allowed;
  }
}

.hover-link {
  &:hover {
    .text-link;
  }

  &[disabled],
  &:hover[disabled] {
    color: @disabled-color;
    cursor: not-allowed;
  }
}

.link-underline {
  .text-link;
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}

.relative {
  position: relative;
}

.margin-center {
  margin-left: auto;
  margin-right: auto;
}

.fullscreen-wrapper {
  min-width: none !important;
  max-width: none !important;
  padding: 0 !important;
  text-align: center;
}

.main-font-size {
  font-size: @font-size-base;
}

.emphasize-font-size {
  font-size: @font-size-lg;
  font-weight: bold;
}

.text-lineThrough {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: line-through;
}

.verticle-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}
