.el-row-container-top {
  background-color: #f5f5f5;
  display: inline-flex;
  align-items: center;
  width: 100vw;
  padding: 6px 16px;
  margin-bottom: 10px;
  .action-button {
    padding: 4px 8px;
    height: 28px;
    .ant-image {
      margin-right: 6px;
      vertical-align: -0.125em;
    }
  }
  .back-button {
    span:nth-child(2) {
      margin-left: 4px;
    }
  }
  .ant-btn[disabled] {
    color: #bfbfbf;
    background-color: #f5f5f5;
    background-image: none;
    svg {
      color: #bfbfbf;
    }
    &:hover {
      border-color: #d9d9d9;
    }
  }
}
.el-row-container-inbox {
  background-color: #f5f5f5;
  display: inline-flex;
  align-items: flex-end;
  // height: 36px;
  width: 100vw;
  padding: 6px 16px;
  margin-bottom: 10px;
  .action-button {
    box-shadow: none;
    padding: 4px 8px;
    text-shadow: none;
    margin-right: 8px;
    height: 28px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-size: 12px;
    background-image: linear-gradient(#ffffff, #e5e5e5);
    color: black;
    &:hover {
      color: #30B6AA;
      border-color: #30B6AA;
    }
  }
  .ant-btn[disabled] {
    color: #bfbfbf;
    background-color: #f5f5f5;
    background-image: none;
    svg {
      color: #bfbfbf;
    }
    &:hover {
      border-color: #d9d9d9;
    }
  }
}

@primary-color: #0268b7;