.order-action {
  padding: 0px 20px;
  .customCard {
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid #f0f0f0;
    .action-wrapper {
      width: 70%;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      // .ant-descriptions-row {
      //   display: flex;
      //   align-items: center;
      //   justify-content: space-around;
      // }
    }
    .ant-descriptions .ant-descriptions-row > th,
    .ant-descriptions .ant-descriptions-row > td {
      padding-bottom: 4px;
    }
    .ant-statistic {
      font-size: 12px;
    }
  }
}

@primary-color: #0268b7;