@paper-color: #74d2d4;
@btn-height-base: 11px;
@border-style-base: solid;
@border-color-base: #d9d9d9;
@border-width-base: 1px;
@delivery-width: 145px;
@paper-width: 200px;
@paper-title: 80px;
@paper-footer: 30px;
@paper-height: @paper-title + @paper-footer;
@corner-size: @btn-height-base + @border-width-base;
@circle-size: 10px;
@circle-separate: 7px;

.paper-check-group {
  .check-item {
    width: @paper-width;
    height: @paper-height + 2 * @border-width-base;
    line-height: initial;
    border: @border-width-base @border-style-base @border-color-base;
  }
  .ant-checkbox-wrapper {
    overflow: hidden;
    padding-right: 2px;
    padding-left: 2px;
  }
  .ant-checkbox {
    display: none;
  }
  .ant-checkbox + span {
    padding-right: 2px;
    padding-left: 2px;
  }
  .ant-checkbox-group-item {
    margin-right: 0;
    margin-top: 4px;
  }
  .ant-checkbox-wrapper-disabled {
    .paper-content {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.25);
    }
    .paper-content-footer {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.paper-flow {
  .ant-checkbox-wrapper-disabled {
    border-top-color: transparent;
  }
  .paper-content {
    background-color: @paper-color;
  }
}

.paper {
  position: relative;
  // height: @paper-height;
  &-content {
    height: @paper-height;
    color: #fff;
    background-color: #f5222d;
    &-title {
      position: relative;
      padding: 8px;
      height: @paper-title;
      em {
        font-size: 28px;
        height: 64px - 14px * 2;
        line-height: 64px - 14px * 2;
      }
      &-corner {
        margin-left: 4px;
        font-size: 14px;
      }
      &-description {
        height: 14px;
        line-height: 14px;
        // font-size: 12px;
        &:last-child {
          line-height: initial;
        }
      }
    }
    &-footer {
      height: @paper-footer;
      line-height: @paper-footer;
      background-color: #fff;
      color: #444;
      text-align: center;
    }
  }
}

.circles {
  position: absolute;
  top: -@circle-size;
  width: 100%;
  height: @circle-size + @circle-size / 2;
  overflow: hidden;
  white-space: nowrap;
  color: #fff;
  &::before {
    content: '';
    position: absolute;
    top: @circle-separate;
    width: 100%;
    border-bottom: @circle-separate dotted currentColor;
  }
  &[disabled] {
    background-color: #f5f5f5;
  }
}
.circles-disabled {
  background-color: #f5f5f5;
}

.coupons-bottom {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // padding-right: 50px;
  font-size: 14px;
}

.popover-modal {
  .ant-popover-message-title {
    padding-left: 0;
  }
}

@primary-color: #0268b7;