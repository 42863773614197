@import '@/style/variable.less';
// @import '@/pages/cart/cart.variable.less';
.imgArea {
  position: relative;
  width: 700px;
  height: 500px;
  // margin: auto;
  // overflow: hidden;
  // & > img {
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -50%);
  //   cursor: move;
  // }
  .pointerZoom {
    cursor: -webkit-zoom-in;
    cursor: zoom-in; /* 大大大 */
    border: 1px solid #eee;
  }
  .viewer-tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    color: #fff;
    display: none;
    font-size: 12px;
    height: 20px;
    left: 50%;
    line-height: 20px;
    margin-left: -25px;
    margin-top: -10px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 50px;
    animation-duration: 3s;
  }
  .waterMark {
    position: absolute;
    text-align: center;
    top: 0;
  }
  .bodyMask {
    position: fixed;
    width: 700px;
    height: 500px;
    top: 0px;
    left: 0px;
    opacity: 0.1;
    pointer-events: none;
    display: flex;
    flex-wrap: wrap;
    z-index: 99999999;
    .mask {
      margin: 50px;
      // transform: rotate(30deg);
      font-size: 10px;
      p {
        margin-bottom: 0;
      }
    }
  }
}
.drawing-modal {
  .ant-radio-button-wrapper {
    padding: 0 30px !important;
  }
  .ant-modal-close-x {
    height: 36px;
  }
}
.content-wrapper {
  margin-top: 27px;
  .content-operation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .imgs-wrapper {
    width: 700px;
    height: 500px;
    display: flex;
    // margin-top: 12px;
    .img-preview {
      display: inline-block;
      width: 470px;
      height: 500px;
      border: 1px solid #eee;
      // margin-top: 30px;
      // margin-bottom: 20px;
      vertical-align: top;
      text-align: center;
      img {
        // margin-top: 35px;
      }
    }
    .imgs-list {
      width: 225px;
      height: 500px;
      margin-left: 30px;
      overflow-y: auto;
      // margin-top: 30px;
      .selected-img {
        border: 1px solid #4c9ee4;
        border-radius: 4px;
      }
      img {
        cursor: pointer;
        margin: 0 2px;
      }
    }
  }
}

@primary-color: #0268b7;