/**
  * 不同分辨率适配，目前要求适配720p、1080p
  * 鉴于没有适配要求之前的统一要求为1200px，因此两个分辨率宽度依然可以设置为1200px
  * 其次做扩展，适配2k、4k
  * 本次适配核心任务是将首页的切割感去除，看起来更一体化
*/
@screen-md: 1900px;
@screen-lg: 2500px;
// 720p   1280*720
// 1080p  1920*1080
@width-break-sm: @main-constants-width; // 1200px
// 2k     2048×1080
@width-break-md: 1200px;
// 4k     3840*2160
@width-break-lg: 1200px;

.main-constants-padding {
  // padding-left: @main-constants-padding;
  // padding-right: @main-constants-padding;
}

.menu-homepage-width-standard {
  width: @main-constants-width;
}
.menu-content-media-width-standard {
  width: @main-constants-width;
}
.media-homepage-menu-height {
  // min-height: 426px;
  min-height: 300px;
  height: @homepage-min-height;
}
.media-homepage-menu-height-standard {
  height: @menu-height;
  min-height: @menu-height;
}
@media screen and (max-width: @screen-md) {
  .media-homepage-width {
    min-width: @width-break-sm;
    max-width: @width-break-sm;
  }
  .menu-content-media-width {
    width: @width-break-sm - @main-constants-padding * 2 - @menu-width;
  }
  .media-homepage-menu-height {
    min-height: 300px;
    height: @homepage-min-height;
  }
  .media-homepage-menu-height-wrapper {
    .slick-track {
      min-height: 426px;
      height: @homepage-min-height;
    }
  }
  .fullscreen-wrapper {
    width: auto;
    min-width: none !important;
    max-width: none !important;
    padding: 0 !important;
  }
}
@media screen and (min-width: @screen-md) and (max-width: @screen-lg) {
  .media-homepage-width {
    min-width: @width-break-md;
    max-width: @width-break-md;
  }
  .menu-content-media-width {
    width: @width-break-md - @main-constants-padding * 2 - @menu-width;
  }
  .media-homepage-menu-height {
    min-height: 460px;
    height: @homepage-min-height;
  }
  .media-homepage-menu-height-wrapper {
    .slick-track {
      min-height: 460px;
      height: @homepage-min-height;
    }
  }
  .fullscreen-wrapper {
    width: auto;
    min-width: none !important;
    max-width: none !important;
    padding: 0 !important;
  }
}
@media screen and (min-width: @screen-lg) {
  .media-homepage-width {
    min-width: @width-break-lg;
    max-width: @width-break-lg;
  }
  .menu-content-media-width {
    width: @width-break-lg - @main-constants-padding * 2 - @menu-width;
  }
  .media-homepage-menu-height {
    min-height: 1200px;
  }
  .fullscreen-wrapper {
    width: auto;
    min-width: none !important;
    max-width: none !important;
    padding: 0 !important;
  }
}
