#customProListStyle {
  font-size: 12px;
  .ant-checkbox-wrapper {
    font-size: 13px;
  }
  .keyWords {
    color: #ff4d4f;
  }
  .ant-divider-horizontal.ant-divider-with-text {
    margin: 7px 0;
  }
  .addService {
    color: #ff4d4f;
    margin-left: 5%;
    display: flex;
    align-items: center;
    .singleService {
      font-size: 10px;
      border: 1px solid #ff4d4f;
      display: flex;
      padding-left: 5px;
      align-items: center;
      text-align: center;
    }
  }
  .ant-divider-inner-text {
    padding: 0 12px;
  }

  .columnStyle {
    .ant-checkbox-wrapper {
      color: #fff;
    }
    margin: 0;
    display: flex;
    color: #fff;
    background-color: #0268b7;
    // height: 48px;
    padding: 4px 12px;
    align-items: center;
    min-height: 50px;
  }
  .trStyle {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    &:hover {
      background: #e9f5fe;
    }
  }
  .trAppendStyle {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    align-items: center;
    background-color: #fcf6f8;
    .serviceTag {
      background-color: rgba(255, 240, 244, 0.48) !important;
      border-width: 1.5px;
      .serviceName {
        font-size: 13px;
        font-weight: 450;
        margin-right: 3px;
        color: rgba(0, 0, 0, 0.85);
      }
      .servicePrice {
        color: #ff4d4f;
      }
    }
  }

  .dataAmtStyle {
    display: flex;
    list-style: none;
    align-items: center;
    li {
      white-space: nowrap;
      margin: 0 5px;
    }
  }

  .contentColumn {
    width: 100%;
    display: flex;
    font-size: 13px;
    align-items: center;
  }

  // .ant-pro-card-body {
  //   padding: 0 0 0 20px !important;
  // }
  .cartList {
    .cartList-card {
      background: #fff;
      margin: 10px 0;
      // border: 1px solid rgba(0, 0, 0, 0.06);
      border: 1px solid rgba(225, 225, 225, 1);
      // &:last-child {
      //   margin-bottom: 0;
      // }

      .cartList-card-header {
        padding: 0 12px;
        height: 36px;
        line-height: 36px;
        background: #f7f9fa;
        border-bottom: 1px solid rgba(225, 225, 225, 1);
        .cartList-card-header-span {
          // margin-left: 15px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.8);
          font-weight: 400;
        }
      }
    }
    .cartList-card:last-child {
      // border-bottom: none;
      margin-bottom: 0;
    }
    .footerStyle {
      background: #fff;
      padding: 0 12px 0 12px;
      // height: 46px;
      .footerStyle-firstLine {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 0;
        .footer-right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .summary {
          .ant-statistic {
            display: inline-block;
          }
          // width: 80%;
          font-size: 13px;
        }
        .footerStyle-btn {
          // font-size: 14px;
          // color: rgba(0, 0, 0, 0.8);
          // line-height: 20px;
          // font-weight: 400;
          // &.btn-del {
          //   color: #0368b7;
          // }
        }
      }
      .footerStyle-secondLine {
        padding: 6px 0;
      }
    }
  }

  .ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 0 !important;
  }

  // .rightColumn {
  //   display: flex;
  //   width: 550px;
  //   justify-content: space-around;
  //   flex-direction: row;
  //   align-items: center;

  //   .totalPrice {
  //     color: #eb0229;
  //   }

  //   &>div {
  //     width: 25%;
  //     text-align: center;
  //   }
  // }
}

// .ant-pro-checkcard-checked {
//   background-color: #fff !important;
//   border-color: #ff4d4f !important;
// }

// .ant-pro-checkcard-checked::after {
//   border-color: #ff4d4f !important;
// }

@primary-color: #0268b7;