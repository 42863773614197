.confirm-Modal {
  .ant-modal-header {
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 50px;
    .ant-modal-title {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }
  }
}

@primary-color: #0268b7;