.addAddr-modal {
  .ant-modal-header {
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 50px;
    .ant-modal-title {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
    }
  }
  .specialItem {
    .ant-form-item-control-input-content {
      border: 1px solid #e4e4e4;
      padding-left: 10px;
      position: relative;
      .label {
        display: block;
        width: fit-content;
        padding: 0 6px;
        // min-width: 40px;
        // max-width: 60px;
        position: relative;
        top: -10px;
        text-align: center;
        background: #fff;
        font-size: 12px;
      }
      .ant-input {
        left: -6px;
        top: -6px;
      }
    }
  }
}

@primary-color: #0268b7;