.img-upload {
  height: 64px;
  .ant-upload-list {
    height: 64px;
    .ant-upload.ant-upload-select-picture-card {
      width: 64px;
      height: 64px;
    }
  }

  .ant-upload-list-picture-card-container {
    width: 64px;
    height: 64px;
    .ant-upload-list-item {
      padding: 0 !important;
      height: 64px;
      width: 64px;
    }
  }
}
// .upload-list-inline .ant-upload-list-item {
//   float: left;
//   width: 200px;
//   margin-right: 8px;
//   .ant-upload-list-item-card-actions .anticon {
//     color: #ff4d4f;
//   }
// }

// .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
//   float: right;
// }
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
  margin-top: 0px;
  .ant-upload-list-item-card-actions .anticon {
    color: #ff4d4f;
  }
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}
.info-text {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
  margin-left: 10px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail:hover {
  opacity: 1;
}

.upload-list-inline
  [class*='-upload-list-rtl']
  .ant-upload-list-item
  .ant-upload-list-item-infoe
  .ant-upload-list-item-thumbnail:hover {
  background-color: #f00;
  // opacity: 1;
}
.upload-list-inline
  [class*='-upload-list-rtl']
  .ant-upload-list-item
  .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  // opacity: 0;
  transition: all 0.3s;
  content: ' ';
}

.ant-row {
  .ant-col {
    margin-top: 4px;
    .img-item {
      width: 200px;
      height: 66px;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-image {
        width: 48px;
        height: 48px;
        .ant-image-mask-info {
          font-size: 12px;
        }
      }
    }
    .file-item {
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-upload-span {
        display: flex;
        align-items: center;
      }
      .ant-upload-list-item-name {
        width: 110px;
        padding-left: 10px;
      }
    }
    .oper {
      a,
      span {
        margin-right: 6px;
      }
    }
  }
}

@primary-color: #0268b7;