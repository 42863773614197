.discount-popover {
  .ant-popover-message-title {
    padding-left: 0 !important;
  }
  .ant-popover-inner-content {
    padding: 2px 10px 10px !important;
  }

  .ant-popover-buttons {
    display: none;
  }
  .dpd-popover-content {
    width: 300px;
    max-height: 400px;
    font-size: 12px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .dpd-table .ant-table-thead > tr > th {
    background-color: #f5f5f5 !important;
    color: #737373;
  }
  .dpd-popover-title {
    text-align: left;
    color: rgb(140, 99, 0);
  }
  .dpd-popover-content::-webkit-scrollbar {
    width: 3px;
    /*设置滚动条的宽度*/
  }
  /* 滚动区域的样式 */
  .dpd-popover-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /*设置滚动条的圆角*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    /*设置内阴影*/
    background: #c1c1c1;
    /*设置滚动条的颜色*/
  }
  /* 滚动条的背景样式 */
  .dpd-popover-content::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
    border-radius: 0;
  }
}

@primary-color: #0268b7;