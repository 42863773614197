.video-upload-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
  position: relative;
}
.change-button {
  position: absolute;
  top: 0;
  right: 20px;
  color: #fff;
}

@primary-color: #0268b7;