@import '../../../style/variable.less';

.headerLogo-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  line-height: 90px;
  padding: 0 20px;
  img {
    object-fit: contain;
    width: @logo-width;
    height: 90px !important;
  }
}

@primary-color: #0268b7;