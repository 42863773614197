// @import '@/style/media.less';
.wrapper {
  position: relative;
  min-height: 100vh;
  // height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // position: relative;
  // min-height: 100vh;
  // padding-bottom: @footer-height; //将padding-bottom大小设置为底部内容的高度
  // box-sizing: border-box;
  // min-width: 1400px;
  .popCircle {
    .fixedList {
      position: fixed;
      z-index: 999;
      right: 0;
      top: 50%;
      transform: 'translateY(-50%)';
      text-align: right;
      background-color: rgba(245, 245, 245, 1);
      box-sizing: border-box;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        border-radius: 4px;
        li {
          background-color: #ffffff;
          margin: 5px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          @keyframes fade {
            from {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          @-webkit-keyframes fade {
            from {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          .message {
            animation: fade 1200ms infinite;
            -webkit-animation: fade 1200ms infinite;
          }
          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: space-around;
            color: rgba(0, 0, 0, 0.6);
            padding: 8px 0;
            // height: 70px;
            font-size: 12px;

            .anticon {
              font-size: 24px;
              color: rgba(0, 0, 0, 0.6);
            }
          }
          &:hover {
            a {
              color: #0368b7;
              .anticon {
                color: #0368b7;
              }
            }
          }
        }

        li:not(:last-child) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
      }
    }
    .popContact {
      position: relative;
      .popContactImg {
        position: absolute;
        width: 100px;
        /* height: 52px; */
        position: absolute;
        top: -34px;
        left: -40px;
        mix-blend-mode: multiply;
      }
    }
  }
}

.msgSendTime {
  font-size: 12px;
  padding-top: 8px;
  font-style: italic;
}
.msgTimeContent {
  margin-left: 5px;
}
.msgClock {
  font-size: 12px;
}
.unClosable {
  .ant-notification-notice-close {
    display: none;
  }
}
.notification-modal {
  .ant-notification-notice-description {
    margin-left: 48px;
  }

  .ant-result {
    padding: 8px 20px;
    .ant-result-icon > .anticon {
      font-size: 50px;
    }
    .ant-result-title {
      font-size: 20px;
    }
    .ant-result-extra {
      margin: 16px 0 0 0;
      .content {
        // display: flex;
        // justify-content: space-between;
        // flex-direction: column;
        .ant-typography {
          display: block;
          font-size: 14px;
          text-align: left;
        }
        .ant-btn {
          margin-top: 4px;
          border-radius: 8px !important;
        }
        // .ant-badge {
        //   display: flex;
        //   width: 80%;
        //   align-items: center;
        //   .ant-badge-status-text {
        //     width: 100%;
        //     display: inline-block;
        //     text-overflow: ellipsis;
        //     white-space: nowrap;
        //     overflow: hidden;
        //   }
        // }
        // .extra {
        //   display: flex;
        //   align-items: center;
        //   justify-content: space-around;
        // }
      }
    }
  }
}

@primary-color: #0268b7;