.modal-content {
  min-height: 200px;
  text-align: center;
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    top: 0;
    left: 0;
  }
  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
    margin-top: 0px;
    .ant-upload-list-item-card-actions .anticon {
      color: #ff4d4f;
    }
  }

  .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
  }
  // .ant-upload {
  //   .ant-btn {
  //     width: 180px;
  //     height: 50px;
  //   }
  // }
  .modal-desc {
    margin-top: 10px;
    font-size: 12px;
    span {
      display: block;
    }
  }
}

@primary-color: #0268b7;