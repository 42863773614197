.rec-tag {
  display: inline-block;
  padding: 0px 6px;
  font-size: 13px;
  background-color: #0088d2;
  border-radius: 20px;
  color: #fff;
}

.trade-tag {
  display: inline-block;
  padding: 0px 6px;
  font-size: 13px;
  background-color: #87d068;
  border-radius: 20px;
  color: #fff;
}

@primary-color: #0268b7;