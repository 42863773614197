@import '../../../style/variable.less';
@import '../../../style/normal.less';

// 表单

.login-form {
  padding: @padding-sm @padding-md;
  .input-icon {
    font-size: 20px;
    color: @input-border-color;
  }
  .ant-input {
    padding-left: @padding-sm !important;
  }
  .form-error {
    visibility: hidden;
    margin-top: -@form-item-margin-bottom;
    height: @form-item-margin-bottom;
    line-height: @form-item-margin-bottom;
    color: @error-color;
    &:not(:empty) {
      visibility: visible;
    }
  }
  .loginAbility {
    font-size: 14px;
  }
}

.login-captcha {
  .ant-input-group-addon {
    padding: 0;
    width: 95px;
    height: @input-height-lg;
    img {
      height: 100%;
    }
  }
}
.loginEditor-wrapper {
  .ant-modal-content {
    .ant-modal-header {
      padding: 0px 0px 0px 14px;
      background-color: #2c66b1;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      padding: 0;
      .bf-controlbar {
        display: none;
      }
    }
    .ant-modal-footer {
      .ant-btn {
        border-radius: 4px;
        width: 100px;
      }
      .ant-btn-default:hover {
        background-color: red;
        color: #fff;
        border: none;
      }
    }
  }
}

@primary-color: #0268b7;