// @import '../../../styles/variables.less';

@table-vertical-gap: 12px;
th.form-required {
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}
.elEditTable-actionButton-container {
  font-size: 12px;
  height: fit-content;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  background: #ffff;
  padding: 0 0 6px 0;
  .action-button {
    box-shadow: none;
    text-shadow: none;
    padding: 4px 8px;
    margin-right: 8px;
    height: 28px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-size: 12px;
    background-image: linear-gradient(#ffffff, #e5e5e5);
    color: black;
    &:hover {
      color: #2a4791;
      border-color: #2a4791;
    }
    .ant-image {
      margin-right: 6px;
      vertical-align: -0.125em;
    }
  }
  .ant-btn[disabled] {
    color: #bfbfbf;
    background-color: #f5f5f5;
    background-image: none;
    svg {
      color: #bfbfbf;
    }
    &:hover {
      border-color: #d9d9d9;
    }
  }
  .elEditTable-actionButton-container-right {
    margin-left: auto;
  }
  .ant-col {
    height: 34px;
    display: initial;
  }
}
.elEditTable-description-container {
  height: fit-content;
  padding: 0 10px;
}
.elEditTable {
  width: 100%;
  .ant-pagination {
    margin: 6px 0;
    justify-content: flex-start;
    .ant-pagination-total-text {
      margin-left: 0;
    }
    .el-pagination-button {
      margin-right: 10px;
    }
    .ant-pagination-prev {
      display: none;
    }
    .ant-pagination-next {
      display: none;
    }
    .ant-pagination-item {
      display: none;
    }
  }

  .ant-table-cell {
    font-size: 12px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: #f0f3fa !important;
  }
  .ant-table-body .ant-table-row-hover {
    background: #f0f3fa !important;
  }
  .ant-table-body .ant-table-row-hover > td {
    background: #f0f3fa !important;
  }
  /*鼠标划过 固定列背景颜色*/
  .ant-table-fixed .ant-table-row-hover {
    background: #f0f3fa !important;
  }
  .ant-table-fixed .ant-table-row-hover > td {
    background: #f0f3fa !important;
  }
  /*鼠标选中颜色*/
  .ant-table-tbody > tr.ant-table-row-selected td {
    background-color: #fffbe6;
  }
  .ant-table-small .ant-table-thead > tr > th {
    background: #f0f3fa !important;
  }
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #e5e5e5 !important;
  }
}

@primary-color: #0268b7;