.thumbnail-wrapper {
  width: 410px;
  display: flex;
  flex-direction: column;
  .thumbnail-bigImg {
    width: 410px;
    height: 410px;
    border: 1px solid #bbb;
  }
}
#thumbnailList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  margin-top: 16px;
  .thumbnail-arrow-left,
  .thumbnail-arrow-right {
    width: 23px;
    height: 100px;
    background-color: #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .thumbnail-content {
    width: 364px;
    padding: 0 16px;
    .swiper-slide img:nth-child(even) {
      /* 样式规则 */
      margin: 0 16px;
    }
  }

  .thumbnail-smallImg {
    width: 100px;
    height: 100px;
    padding: 5px;
    border: 1px solid #bbb;
    background-size: cover;
  }
  .thumbnail-selected-smallImg {
    border: 1px solid #4095e5;
  }
}

span.parrot-icon.parrot-icon-default {
  display: none;
}

// .thumbnailBorder {
//   // overflow: hidden;
//   margin: 5px;
//   // background: url("https://www.wulihub.com.cn/gc/Wezyzk/images/%E7%BD%91%E7%AB%99%E9%A6%96%E9%A1%B5/u670.png");
//   background-size: cover;
//   // background: url('');
// }

// .parrot-preview-magnifier {
//   height: 300px;
//   width: 70%;
// }

@primary-color: #0268b7;