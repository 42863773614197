@import '@/style/biz.less';
@import '@/style/variable.less';

@coupon-width: 440px;
.coupon-modal {
  z-index: @zindex-tooltip + 2;
  .ant-modal {
    width: @padding-lg * 2 + @coupon-width !important;
  }
  .ant-modal-content {
    background: transparent !important;
    box-shadow: none;
    .ant-modal-close {
      top: @padding-md;
      right: @padding-md;
      .ant-modal-close-x {
        height: 80px;
        line-height: 80px;
        // font-size: 16px;
      }
    }
  }
}

.coupon-container {
  width: @coupon-width;
  position: relative;
  .header {
    width: 100%;
    position: absolute;
    top: -115px;
    left: 0;
  }
  .coupon {
    width: 100%;
    max-height: 544px;
    border-radius: 8px;
    padding: @padding-md 40px;
    .title {
      margin-bottom: @padding-md;
      color: @white;
      div:nth-child(1) {
        font-size: 24px;
        margin-bottom: @padding-xs;
      }
      div:nth-child(2) {
        font-size: @font-size-lg;
      }
    }
    .main {
      max-height: 330px;
      overflow: auto;
      .item {
        width: 100%;
        height: 102px;
        background: @white;
        border-radius: @padding-xs;
        margin-bottom: @padding-xs;
        div.item-part {
          float: left;
          height: 100%;
          .receive-btn {
            width: 50px;
            padding: 0 8px;
            margin-top: calc(50px - @padding-md);
            color: #ffffff;
            background-color: #005abf;
          }
          .received-btn {
            width: 50px;
            padding: 0 8px;
            margin-top: calc(50px - @padding-md);
            background-color: #cccccc;
            color: #ffffff;
          }
        }
        .left-amout {
          width: 100px;
          padding: 20px 12px;
          div:nth-child(1) {
            color: @error-color;
            margin-bottom: @padding-xs;
            span {
              font-size: 24px;
            }
          }
        }
        .middle-radius {
          width: @padding-md;
          position: relative;
          overflow: hidden;
          &:before {
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 12px;
            background: #5aa2f5;
            position: absolute;
            left: 0;
            top: -6px;
          }
          &:after {
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 12px;
            background: #5aa2f5;
            position: absolute;
            left: 0;
            bottom: -6px;
          }
        }
        .right-desc {
          width: calc(100% - 160px - @padding-md);
          padding: @padding-md 0px;
          div:nth-child(1) {
            color: #333;
            font-weight: bold;
            margin-bottom: @padding-xs;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 195px;
          }
          div:nth-child(2) {
            margin-bottom: @padding-xs / 2;
          }
        }
      }
    }
    .arrow {
      margin: @padding-xs 0 @padding-md;
    }
    .submit-btn {
      background: #005abf;
      border-radius: 4px;
      height: 40px;
      line-height: 40px;
      color: @white;
    }
  }
}

.receive-container {
  width: 100%;
  background: @white;
  padding: @padding-md * 3 @padding-xs @padding-xs;
  .desc {
    margin: @padding-lg 0;
  }
  .operationBtn {
    margin-bottom: @padding-lg;
    button:nth-child(1) {
      margin-right: @padding-xs;
    }
    button:nth-child(2) {
      margin-left: @padding-xs;
    }
  }
}

@primary-color: #0268b7;