@import '@/style/variable.less';

.cancel-modal {
  .ant-list-grid .ant-col {
    .ant-list-item {
      height: 40px;
      line-height: 40px;
      text-align: center;
    }
    .default-border {
      border: 1px solid #e4e4e4;
    }
    .selected-border {
      border: 1px solid #ee3732;
      color: #ee3732;
    }
  }
}

@primary-color: #0268b7;