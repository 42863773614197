.goodsInfo {
  display: flex;
  align-items: center;
  padding-left: 8px;
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 0;
  }
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    font-size: 12px;
    color: #666;
  }
  .goodsContent {
    height: 80px;
    text-align: left;
    margin-left: 16px;
  }
}

@primary-color: #0268b7;