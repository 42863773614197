#amtRegion {
  ul {
    list-style: none;
  }
  //   #amtLabel {
  //     margin-right: 60px;
  //   }
  #amtValue {
    width: 150px;
    // margin-left: 50px;
  }
  display: flex;
}

@label-width: 85%;

.order-info-list {
  .ant-descriptions-item {
    padding-bottom: 0 !important;
  }
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    display: inline-block;
    text-align: right;
  }

  .ant-descriptions-item-label {
    width: @label-width;
  }

  .ant-descriptions-item-content {
    width: calc(100% - @label-width);
  }
  .ant-statistic {
    display: inline-block;
    .ant-statistic-content,
    .ant-statistic-content-value-decimal {
      font-size: 14px;
    }
  }
}

@primary-color: #0268b7;