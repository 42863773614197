@import './variable.less';
@import './biz.less';
@import './normal.less';
// @import './biz.less';
// 引入位置影响作用域等级，目前为调整待确定状态，适配优先级最高，之后可能考虑上移，防止以后运维时期临时添加业务样式无法轻松生效
@import './media.less';
@import 'swiper/swiper-bundle.css';

/* 全局的整体样式 */
@globalFontStyle: 13px;
// html {
//   overflow-x: hidden;
// }
// body {
//   position: inherit !important;
//   width: auto !important;
//   // overflow: revert !important;
// }

body {
  overflow-x: hidden;
  #root {
    width: 100vw; // calc(100vw + 24px);
    // overflow-x: scroll;
    overflow-x: scroll;
    overflow-y: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  #root::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.ant-message {
  z-index: 2000;
  top: 40vh !important;
}
.ant-table.ant-table-small {
  font-size: 12px !important;
}
.infinite-scroll-component::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
.textOverFlow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-max-overLine2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-box-orient: vertical;
  -moz-line-clamp: 2;
  overflow: hidden;
}
.text-max-overLine3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  -moz-box-orient: vertical;
  -moz-line-clamp: 3;
  overflow: hidden;
}
.desc {
  // font-family: '微软雅黑', sans-serif;
  font-weight: 400;
  // font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.65);
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  // max-height: 150px;
  overflow: hidden;
}

.price {
  // font-family: '微软雅黑', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  // white-space: nowrap;
  color: #ff4d4f;
  // text-overflow: ellipsis;
  // -ms-text-overflow: ellipsis;
  // -o-text-overflow: ellipsis;
  // // max-height: 150px;
  // overflow: hidden;
}

.ant-pagination,
.ant-select,
.ant-form label,
.ant-input-group-addon {
  font-size: @globalFontStyle;
}

// 调整水印透明度
.ant-pro-layout-watermark {
  opacity: 0.5;
}

//图片加载样式
.spinWrapper {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
  width: 33px;
  height: 33px;
  color: #eeeeee;
  background: rgba(0, 0, 0, 0.6);
}
.swiper-button-prev {
  left: 0;
  border-radius: 0px 18px 18px 0px;
}
.swiper-button-next {
  right: 0;
  border-radius: 18px 0 0 18px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px;
  transition: width 1s;
}

.el-btn-default {
  box-shadow: none;
  text-shadow: none;
  margin-right: 8px;
  height: 28px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 14px;
  background-image: linear-gradient(#ffffff, #e5e5e5);
  color: black;

  &:hover {
    color: #0088d2;
    border-color: #0088d2;
  }
}

.ant-image {
  .ant-image-img {
    height: 100%;
    border: 1px solid #efefef;
  }
}

img {
  object-fit: fill;
  object-position: center center;
}

.ant-typography {
  font-size: 13px;
}

.ant-space {
  .ant-space-item {
    font-size: @globalFontStyle;
  }
}

.ant-descriptions {
  .ant-descriptions-row {
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      font-size: @globalFontStyle;
    }
  }
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 8px;
  }
}
.ant-card {
  .ant-card-head {
    .ant-card-head-title {
      padding: 10px 0;
      .block {
        font-size: 16px;
        padding: 0;
        text-indent: 0;
        font-weight: bold;
      }
    }
  }
}

.ant-modal {
  .ant-modal-content {
    background: #ffffff;
    border-radius: 8px;
  }

  .ant-modal-header {
    height: 40px;
    width: 100%;
    border-radius: 8px 8px 0 0;
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 50px;

    .ant-modal-title {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.8);
      height: 40px;
      line-height: 40px;
      font-weight: 500;
      text-align: left;
    }
  }

  .ant-modal-body {
    .ant-table-content .ant-table-cell {
      font-size: @globalFontStyle;

      .ant-input-group-wrapper .ant-input {
        font-size: @globalFontStyle;
        text-align: center;
      }
      .ant-statistic {
        .ant-statistic-content {
          .ant-statistic-content-value-int {
            font-size: @globalFontStyle;
            // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            //   'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
            //   'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
            //   'Noto Color Emoji';
          }
        }

        .ant-statistic-content-value-decimal {
          font-size: @globalFontStyle;
          // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          //   'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
          //   'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
          //   'Noto Color Emoji';
        }
        .ant-statistic-content-suffix {
          font-size: @globalFontStyle;
          // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          //   'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
          //   'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
          //   'Noto Color Emoji';
        }
      }
    }
  }

  .ant-modal-close {
    height: 40px;

    .ant-modal-close-x {
      height: 40px;
      line-height: 40px;
      font-size: 12.3px;
    }
  }
}

.ant-image {
  vertical-align: middle;
}

.ant-popover-inner-content {
  padding: 6px !important;
}

.ant-form-item-control-input {
  .ant-picker-input input {
    font-size: 14px;
  }
  input {
    font-size: 14px;
  }
}

.detail-wrapper {
  .top-bottom {
    margin: 6px;
    height: 30px;
    background-color: #fff;

    .footer-btn {
      margin: 0 5px;
      height: 30px;
    }
  }
}

.green-color {
  color: #0088d2;

  svg {
    color: #0088d2;
  }
}

.vipLoginHyd {
  // .ant-modal-body {
  //   min-height: 360px;
  // }
  .ant-modal-header {
    background-color: @btn-info-color;
    .ant-modal-title {
      color: #fff;
    }
  }
}

@primary-color: #0268b7;