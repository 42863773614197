.basic-card {
  padding: 0px;
  .card-title {
    // .ant-tabs-top > .ant-tabs-nav::before {
    //   border-bottom: 0;
    // }
    .basic-card-tab {
      .ant-tabs-nav {
        background-color: #fff;
        height: 50px;
        line-height: 50px;
        .ant-tabs-tab {
          border: none;
        }
        .ant-tabs-nav-list {
          left: 20px;
          border-bottom: 1px solid #f0f0f0;
        }
        .ant-tabs-tab-active {
          border-bottom: 2px solid #0079fe;
        }
        .ant-tabs-extra-content {
          width: 90%;
        }
      }
    }
  }
}

@primary-color: #0268b7;