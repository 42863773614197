@import '@/style/biz.less';
@import '@/style/variable.less';

@corner-size: @btn-height-base + @border-width-base;
.services-modal {
  // .ant-modal-body{
  //   padding-bottom: 10px;
  // }
  .check-link {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
  }
  .ant-checkbox-group {
    width: 100%;
  }
  .ant-checkbox-wrapper {
    overflow: hidden;
    padding: 4px;
    &span:last-child {
      position: relative;
      display: block;
      padding: 0;
    }
  }
  .ant-checkbox-wrapper-checked {
    .check-item {
      border: 1px solid #ff4d4f;
      color: #ff4d4f;
    }
  }
  .ant-checkbox {
    display: none;
  }
  .ant-checkbox-wrapper {
    margin-right: 0;
    width: 33%;
    & span:last-child {
      width: 100%;
      text-align: center;
      padding: 0 0 0 @padding-xs;
    }
  }
  .check-item {
    padding: 0 @padding-xs / 2;
    width: 100%;
    height: 46px;
    line-height: 46px;
    border: @border-width-base @border-style-base @border-color-base;
  }
  .service-tip {
    padding: 10px 0;
    color: #f5222d;
    font-size: 14px;
  }
}

@primary-color: #0268b7;