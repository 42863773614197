#filterItemStyle {
  padding: 0 5%;

  // display: flex;
  // flex-direction: row;
  // align-items: center;
  .filterForm {}

  .searchButtons {}
}

// #orderContent {
//   .orderTopStyle {
//     display: flex;
//     text-align: center;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-around;

//     span {
//       font-family: '微软雅黑', sans-serif;
//       font-weight: 400;
//       font-style: normal;
//       color: #999999;
//       line-height: 28px;
//       line-height: 400%;
//     }
//   }
// }

#orderCardPrice {
  width: 10%;
  height: 100%;
  text-align: center;
  // padding-top: 10px;
  // display: ;
  position: absolute;
  left: 48%;
  top: 10%;
  // display: inline-block;

  // padding-top: 30%;
  // line-height: 1000%;
  // display: flex;
  span {
    // display: block;
    // margin-top: 30px;
    // margin-top: 300px;
    // line-height: 300%;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    /*宽高的一半*/
    // margin-left: -50%;
    // margin-top: -50%;
    // margin: 50% auto;
    // transform: translateY(-50%);
  }

  // text-align: center;
  // align-items: center;
}


#pagingStyle {}

#orderCard {
  width: 40%;
  display: inline-block;
  height: 100%;

  i {
    margin-right: 5px;
  }

  span {
    font-family: '微软雅黑', sans-serif;
    // font-weight: 400;
    font-style: normal;
    color: rgba(0, 0, 0, 0.6);
    // line-height: 28px;
    // line-height: 400%;
  }
}
@primary-color: #0268b7;