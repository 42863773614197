.telephoneModal {
  .ant-modal-header {
    background: #0268b7;
    .ant-modal-title {
      font-size: 18px;
      text-align: center;
      color: #fff;
    }
  }
  .ant-alert-message {
    color: #ff4d4f;
    font-size: 13px;
  }
  .telephone-content {
    height: 100px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .left-content {
      margin-left: 8px;
      h1 {
        margin-bottom: 0 !important;
      }
    }
  }
}

@primary-color: #0268b7;