.modalBox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .pBox {
    display: flex;
    flex-direction: column;
  }
  .cBox {
    display: flex;
    flex-direction: column;
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
}

@primary-color: #0268b7;