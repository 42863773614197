@import '@/style/variable.less';
@import '@/style/normal.less';

.footerContent {
  .x-fill-100;
  // height: 80px;
  margin-top: 20px;
  text-align: center;
  // position: fixed;
  // bottom: 0;
  // font-size: 13px;
  .copyRight {
    height: 80px;
    line-height: 80px;
  }
  a {
    color: @text-color;
  }
}

@primary-color: #0268b7;