.table-toolTip {
  .ant-tooltip-content {
    .ant-tooltip-arrow-content {
      background-color: #fff;
    }
    .ant-tooltip-inner {
      color: #f5222d;
      background: #fff;
      font-size: 12px;
    }
  }
}

@primary-color: #0268b7;