.el-imgupload {
  height: 36px;
  .ant-upload-list {
    height: 36px;
    .ant-upload {
      height: 36px;
    }
  }

  .ant-upload-list-picture-card-container {
    height: 36px;
    .ant-upload-list-item {
      padding: 0 !important;
      height: 36px;
      width: 48px;
    }
  }
}
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
  margin-top: 0px;
  .ant-upload-list-item-card-actions .anticon {
    color: #ff4d4f;
  }
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}
.info-text {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
  margin-left: 10px;
}

.ant-upload-list-item-thumbnail {
  position: relative;
}
.mask {
  // display: none;
  opacity: 0;
  width: 60px;
  text-align: center;
  height: 66px;
  color: #fff;
  font-size: 12px;
  position: absolute;
  transition: opacity 0.3s;
  white-space: nowrap;
  top: 0;
  left: 0;
  padding-top: 22px;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  pointer-events: none;
}

.upload-list-inline:hover ~ .mask {
  opacity: 100;
}

.ant-row {
  .ant-col {
    margin-top: 4px;
    .img-item {
      width: 200px;
      height: 66px;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-image {
        width: 48px;
        height: 48px;
        .ant-image-mask-info {
          font-size: 12px;
        }
      }
    }
    .file-item {
      width: 200px;
      height: 66px;
      padding: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-upload-span {
        display: flex;
        align-items: center;
      }
      .ant-upload-list-item-name {
        width: 110px;
        padding-left: 10px;
      }
    }
    .oper {
      a,
      span {
        margin-right: 6px;
      }
    }
  }
}

@primary-color: #0268b7;