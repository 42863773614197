.el-button {
  display: inline-block;
  .action-button {
    box-shadow: none;
    padding: 4px 8px;
    text-shadow: none;
    margin-right: 8px;
    height: 28px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-size: 12px;
    background-image: linear-gradient(#ffffff, #e5e5e5);
    color: black;
    &:hover {
      color: #30b6aa;
      border-color: #4c90f6;
    }
  }
  .ant-btn[disabled] {
    color: #bfbfbf;
    background-color: #f5f5f5;
    background-image: none;
    svg {
      color: #bfbfbf;
    }
    &:hover {
      border-color: #d9d9d9;
    }
  }
  .ant-btn-primary {
    background-color: #30b6aa;
    border-color: #30b6aa;
    &:hover {
      background-color: rgba(48, 182, 170, 0.6);
      border-color: #30b6aa;
    }
    &:focus {
      background-color: rgba(48, 182, 170, 0.6);
      border-color: #30b6aa;
    }
  }
}

@primary-color: #0268b7;