.demandNum-popover {
    .ant-popover-message{
     padding: 0 !important;
    }
    .ant-popover-message-title{
      padding: 10px 20px 10px 20px;
      .ant-form .ant-input-group{
        margin-bottom: 6px;
      }
    }
    .ant-popover-buttons {
      display: none;
    }
    .pack-unit{
      display: inline-block;
      height: 32px;
      line-height: 32px;
      margin-left: 8px;
      font-size: 12px;
    }
    .pack-text,
    .pack-text1 {
      position: relative;
      width: 30px;
      height: 24px;
      display: inline-block;
      margin-left: 6px;
      .text1,
      .text2 {
        position: absolute;
        height: 20px;
        line-height: 20px;
      }
      .text1 {
        top: 0px;
      }
      .text2 {
        top: 20px;
      }
    }
    .pack-text1 {
      width: 20px;
    }
  }
@primary-color: #0268b7;