@CarouselHeight: 400px;

.homeContainer {
  display: flex;
  padding: 0;
  .inlayContent {
    cursor: pointer;
  }
  .ant-row {
    margin: 0 !important;
  }
  text-align: center;
  height: @CarouselHeight;
  .leftDraw {
    margin: 7px 0;
    padding: 0 4px 0 0 !important;
    display: flex;
    flex-direction: column;
    // width: 795px;
    .banner-picture {
      margin-left: 0;
    }
  }
  .rightDraw {
    margin: 7px 0;
    padding: 0 0 0 4px !important;
    display: flex;
    flex-direction: column;
    // width: 795px;
    .banner-picture {
      margin-right: 0;
    }
  }

  .banner-picture {
    // padding: 6px 0;
    position: relative;
    // margin-top: 7px;
    .ant-image .ant-image-img {
      border: none;
    }
    .inlayContent {
      z-index: 100;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      p {
        line-height: 60px;
        font-size: 20px;
        color: #ffffff;
        font-weight: 700;
        background: rgba(255, 255, 255, 0.2);
        width: 200px;
        border-radius: 2px;
        height: 60px;
        margin: 1em 0;
      }

      // left: 50%;
      // right: 50%;
      // transform: translate(-50%, -50%);
    }
    img {
      border-radius: 5px;
    }
  }

  #rightContent {
    width: 389px;
    height: 100%;
  }

  // .ant-carousel .slick-dots li button {
  //   display: none !important;
  // }
  // .ant-carousel .slick-dots button {
  //   display: none !important;
  // }
  .slick-dots li button:before {
    opacity: 0 !important;
  }

  // .slick-dots li button {
  //   display: none !important;
  // }

  .nav {
    z-index: 30;
    width: 20%;
    padding: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-color: rgba(51, 51, 51, 0.8);

    ul {
      padding: 0;

      li {
        font-size: 14px;
        // padding-left: 20%;

        &:hover {
          background: #0088d2;
        }

        cursor: pointer;
        height: 50px;
        line-height: 50px;
        // margin: 30px 0;
        list-style: none;
        color: rgb(255, 255, 255);
      }
    }
  }
}

.widthWrapper {
  background: rgba(245, 245, 245, 1);
}

@primary-color: #0268b7;