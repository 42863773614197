.el-import-modal {
  .ant-modal-body {
  }
  .el-import-table {
    tr {
      line-height: 15px;
    }
    .import-button-box {
      background: #f5f5f5;
      padding: 4px 12px 4px 16px;
      .import-button-box-span {
        margin-left: 16px;
        font-family: PingFangSC-Regular, sans-serif;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.4);
        line-height: 20px;
      }
    }
    /*鼠标划过 行背景颜色*/
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
      background-color: #f0f3fa !important;
    }
    .ant-table-body .ant-table-row-hover {
      background: #f0f3fa !important;
    }
    .ant-table-body .ant-table-row-hover > td {
      background: #f0f3fa !important;
    }
    /*鼠标划过 固定列背景颜色*/
    .ant-table-fixed .ant-table-row-hover {
      background: #f0f3fa !important;
    }
    .ant-table-fixed .ant-table-row-hover > td {
      background: #f0f3fa !important;
    }
    /*鼠标选中颜色*/
    .ant-table-tbody > tr.ant-table-row-selected td {
      background-color: #e9f5fe;
    }
    .ant-table-small .ant-table-thead > tr > th {
      background: #f0f3fa !important;
      font-weight: bold;
    }
    .ant-table-thead > tr > th {
      border-bottom: 1px solid #e5e5e5 !important;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td {
      border-right: 1px solid #e5e5e5 !important;
    }
  }
}

@primary-color: #0268b7;