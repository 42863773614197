/*
 * 其他页面使用Popover和Popconfirm时样式被覆盖，所以在这里给div加类名popupSelection
 */
.popupSelection {
  .ant-popover-arrow {
    border: none;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  }
  .ant-popover-content {
    // position: absolute;
    top: 0 !important;

    .ant-pro-table-search {
      height: 0 !important;
      padding: 0 !important;
    }
  }
  // .ant-popover {
  //   top: 20px !important;
  // }
  .ant-btn-icon-only.ant-btn-sm {
    height: 28px;
    width: 28px;
  }
}
.popupModal {
  .ant-modal-body {
    padding: unset !important;
  }
  .elSearchTable-queryForm-container {
    border-bottom: unset !important;
  }
  .elSearchTable-actionButton-container {
    padding: unset !important;
  }
  &-center {
    &-btn {
      position: relative;
      top: 50%;
      svg {
        color: #fff;
      }
    }
  }
  &-right {
    border-left: 1px solid #eee;
    display: flex;
    flex-direction: column;
    &-head {
      background-color: #eee;
      line-height: 38px;
      padding: 0 10px;
    }
    &-content {
      flex-grow: 1;
      position: relative;
      ul {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: auto;
        list-style: none;
        padding: 5px 10px;
        li {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 30px;
          position: relative;
          line-height: 25px;
          .btn {
            &-delete {
              position: absolute;
              height: 25px;
              top: 0;
              right: 0;
              display: none;
            }
          }
          &:hover {
            .btn-delete {
              display: block;
            }
          }
        }
      }
    }
  }
}

@primary-color: #0268b7;