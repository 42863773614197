@import '../../../style/variable.less';

p {
  margin: 0;
  padding: 0;
}

.quotationtemplateModal {
  top: 10px;

  .ant-modal-header {
    background: #ececec;
  }

  .ant-modal-title {
    color: #000;
    font-weight: bolder;
  }

  .ant-modal-body {
    padding: 0;

    .modal-header {
      width: 100%;
      padding: 20px 40px 5px;
      text-align: right;

      p {
        font-size: 12px;
      }
    }

    .ant-divider-horizontal {
      width: calc(100% - 80px);
      min-width: calc(100% - 80px);
      margin: 0 40px;
    }

    .modal-title {
      font-size: 22px;
      text-align: center;
      font-family: inherit;
      font-weight: 550;
      line-height: 1.1;
      color: inherit;

      p {
        margin: 16px 0;
      }
    }

    .modal-description {
      padding: 20px 40px 0;
      // .cart-list {
      //   .ant-descriptions-item {
      //     padding-bottom: 0;
      //   }
      //   .ant-descriptions-item-label,
      //   .ant-descriptions-item-content {
      //     width: 50%;

      //   }
      //   .ant-statistic {
      //     display: inline-block;
      //     .ant-statistic-content,.ant-statistic-content-value-decimal {
      //       font-size: 14px;
      //     }
      //   }
      // }
    }

    .modal-content {
      padding: 10px 40px;

      p {
        text-align: left;
        margin-bottom: 5px;
      }
    }

    .template-table {
      width: 100%;

      tr>td {
        text-align: center;
        border: 1px dotted #adadad;
      }

      tr>td.price,
      tr>td.count,
      tr>td.realQty,
      tr>td.amount {
        white-space: nowrap;
      }

      tr.total {
        height: 30px;
        line-height: 30px;
      }
    }

    .modal-footer {
      padding: 10px 85px;

      // .footerRow {
      //   height: 30px;
      // }
    }
  }

  .ant-modal-footer {
    text-align: center;
    .ant-btn{
      width: auto !important;
    }
  }
}

@primary-color: #0268b7;