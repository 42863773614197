.prod-content {
  width: 100%;
  .list-item {
    padding-top: 10px;
    .picCom {
      // .icon {
      //   position: absolute;
      //   right: 0;
      //   min-height: 22px;
      //   top: 0;
      //   background-image: linear-gradient(135deg, #ed2020 0%, #e41e1e 100%);
      //   border-radius: 0px 0px 0px 16px;
      //   padding-left: 6px;
      //   font-family: PingFangSC-Regular;
      //   font-size: 12px;
      //   color: #ffffff;
      //   line-height: 18px;
      //   font-weight: 400;
      // }
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #f5f5f5;
    .itemName {
      margin: 5px;
    }
    .itemDesc {
      font-size: 12px;
      color: #99a6c4;
    }
    .itemPrice {
      margin: 5px;
      padding: 0;
      text-align: center;
    }
  }
}

@primary-color: #0268b7;