.switchAddr-modal {
  .ant-modal-content > .ant-modal-header {
    border: none;
  }
  .ant-modal-content > .ant-modal-header > .ant-modal-title {
    text-align: left;
    font-size: 16px;
  }
  .ant-modal-body {
    .ant-list-items {
      .ant-list-item {
        padding-left: 20px;
        padding-right: 20px;
      }
      .addr-Item {
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid #e4e4e4;
      }
      .active-item {
        border: 1px solid #f00;
      }
    }
    .ant-descriptions {
      .ant-descriptions-row {
        .deliveryAddr-desc {
          .ant-descriptions-item-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
  .ant-modal-footer {
    text-align: center;
  }
}

@primary-color: #0268b7;