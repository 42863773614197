@import '../../../style/variable.less';
@import '../../../style/biz.less';

.tagSelect {
  min-height: 28px;
  position: relative;
  max-height: @btn-height-base;
  // margin-left: -8px;
  overflow: hidden;
  line-height: @btn-height-base;
  transition: all 0.3s;
  user-select: none;
  display: flex;
  justify-content: space-between;
  .ant-tag {
    // margin-right: 12px;
    // margin-right: @padding-sm;
    // padding: 0 @padding-xs;
    font-size: @font-size-base;
    // color: @text-color;
    color: #666666;
    line-height: 20px;
    font-weight: 400;
  }
  .ant-tag-checkable-checked {
    color: @white;
  }
  &.expanded {
    max-height: 200px !important;
    transition: all 0.3s;
    overflow-y: auto;
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
  &.addBtn {
    max-height: 200px !important;
    transition: all 0.3s;
    overflow-y: auto;
  }
  .trigger {
    // position: absolute;
    // top: 0;
    // right: 0;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    align-items: center;
    height: @btn-height-base;
    i {
      font-size: 12px;
    }
  }
  // &.hasExpandTag {
  //   padding-right: 160px;
  // }
  // &.hasMore {
  //   padding-right: 170px;
  // }
  .tag-control {
    background-color: @disabled-bg;
    &.ant-tag-checkable-checked {
      background-color: @primary-color;
    }
  }
}

@primary-color: #0268b7;