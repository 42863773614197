@import '@/style/variable.less';

.orderType-checktag-wrapper {
  .ant-tag-checkable {
    height: 32px;
    line-height: 32px;
    background: @white;
    margin: 0;
    width: 50%;
    text-align: center;
    box-sizing: border-box;
    font-size: 14px;
  }
  .ant-tag-checkable:nth-child(1) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-right: none;
  }
  .ant-tag-checkable:nth-child(2) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #d9d9d9;
  }
  .ant-tag-checkable-checked {
    color: @white;
    background-color: @info-color;
    border-color: @info-color;
    &:active {
      color: @white;
      background-color: lighten(@info-color, 5%);
      border-color: lighten(@info-color, 5%);
    }
    &:hover {
      color: @white;
      background-color: lighten(@info-color, 5%);
      border-color: lighten(@info-color, 5%);
    }
  }
}

@primary-color: #0268b7;