.table-list-imgs-modal {
  .ant-modal {
    min-width: 722px;
  }
  .ant-modal .ant-modal-close .ant-modal-close-x {
    width: 40px !important;
    font-size: 18px !important;
  }
  .ant-modal-body {
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 20px;
    height: 520px;
  }
  .img-preview {
    display: inline-block;
    width: 470px;
    height: 100%;
    border: 1px solid #eee;
    vertical-align: top;
    text-align: center;
    img {
      // margin-top: 35px;
      object-fit: contain;
    }
  }
  .imgs-list {
    // box-sizing: border-box;
    // display: inline-block;
    // width: 228px;
    // height: 470px;
    // overflow-y: auto;
    // margin-top: 30px;
    // text-align: center;
    width: 188px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-content: flex-start;
    margin-left: 20px;
    .selected-img {
      border: 1px solid #4c9ee4 !important;
      border-radius: 4px;
    }
    img,
    .file-item {
      cursor: pointer;
      border: 1px solid #eee;
      margin-bottom: 4px;
    }
    .file-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@primary-color: #0268b7;