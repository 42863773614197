// for business,
// used to over-write antd style or define the project only style.

// eg. start ----
// do not use it

@info-text-color: rgba(0, 0, 0, 0.65);

// 禁用复制
.disable-copy {
  // user-select: none;
}

.tm-width {
  width: 1800px;
  max-width: 1800px;
  min-width: 1800px;
}
// .collapse-filter {
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   line-height: 3;
//   background: #ffffff;
//   border: 1px solid rgba(255, 255, 255, 1);
//   border-bottom: 0;
// }

// .goodsClean-item-style {
//   p {
//     line-height: 10px;
//   }
//   border: 1px solid #f5f5f5;
//   text-align: center;
//   &:hover {
//     cursor: pointer;
//     border-color: #93cceb;
//   }
// }

@btn-info-color: @info-color;

.container-height {
  min-height: @container-min-height;
  // height: 70%;
  // min-height: unset;
  // min-height: 81vh;
  display: flex;
  // overflow-y: auto;
  // overflow-x: hidden;
  flex-direction: column;
  border: 1px solid transparent;
  // border: none;
  // overflow: hidden;  // 解决子元素设置margin-top导致父元素掉下来的问题
  // background-color: rgba(245, 245, 245, 1);
  background-color: #fff;
}

.height-fix {
  // padding-bottom: @footer-copyright;
  // margin-bottom: @footer-copyright;
}

// eg. end ----
.ant-btn-btn-info,
.btn-info {
  color: @white;
  background-color: @btn-info-color;
  // border-bottom: solid 2px color(~`colorPalette('@{btn-info-color}', 8) `);
  border-color: @btn-info-color;

  &:active {
    color: @white;
    // stylelint-disable-next-line
    background-color: lighten(@btn-info-color, 5%);
    // stylelint-disable-next-line
    border-color: lighten(@btn-info-color, 5%);
  }

  &:hover {
    color: @white;
    // stylelint-disable-next-line
    background-color: lighten(@btn-info-color, 5%);
    // stylelint-disable-next-line
    border-color: lighten(@btn-info-color, 5%);
  }

  &:focus {
    // stylelint-disable-next-line
    border-color: lighten(@btn-info-color, 5%);
  }
}

.ant-btn-btn-success,
.btn-success {
  color: @white;
  background-color: @success-color;
  border-color: @success-color;

  &:hover {
    color: @white;
    background-color: lighten(@success-color, 5%);
    border-color: lighten(@success-color, 5%);
  }

  &:focus {
    border-color: lighten(@success-color, 5%);
    background-color: lighten(@success-color, 5%);
  }
}

// global table custom, tm need a light blue table header. and a compact layout.
.tm-table {
  .ant-spin-blur {
    opacity: 1;
  }

  .ant-spin-container,
  .ant-spin-nested-loading {
    background-color: transparent;
  }

  .ant-spin-container::after {
    background-color: transparent;
  }

  .ant-table {
    font-size: @font-size-base - 1px;
    background-color: @white;
  }

  .ant-table-thead > tr > th {
    background-color: @table-color !important;
    // color: @black;
    color: white;
    font-size: 12px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table-footer {
    padding: @table-padding-vertical / 2 @table-padding-horizontal / 4;
  }

  .ant-table-body {
    margin: 0 !important;
  }

  //表格body和分页器字体颜色和老商城保持一致
  .ant-table-tbody,
  .ant-table-pagination {
    color: @text-color;
  }

  &-title-wrapper {
    display: block;
    margin-bottom: @padding-xs / 2;
  }

  .ant-statistic {
    display: inline-block;
  }

  .ant-statistic-content {
    color: @text-color;
    font-size: @font-size-base - 1px;
  }

  .ant-statistic-content-value-decimal {
    font-size: @font-size-base - 1px;
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: @table-row-hover-bg !important;
  }

  /**
  * mark: 注意，如果你加的某个图标因为下面代码的原因被强制变色了，请给受影响的图标加上 .natural
  * 下面代码的调整是全局table的通用样式
  */
  // .anticon-shopping-cart,
  // .anticon-delete,
  // .anticon-setting,
  // .anticon-star,
  .anticon {
    &:not(.natural) {
      font-size: 1em;
      color: @default-color;
    }
  }

  .ant-item-cart,
  .ant-item-delete {
    font-size: 1.25em;
    color: @table-color;
  }

  .ant-item-add-cart {
    font-size: 1.25em;
    color: @error-color;
  }

  // info 按钮上面的 loading 需要处理一下
  .loading-white {
    .anticon-loading {
      color: @white;
    }
  }
}

// just for search form.
// a compact and custom layout.
.tm-search-form-layout {
  .ant-form-item {
    margin-bottom: @padding-xs;
  }

  .ant-form-item-label {
    width: 60px;
    line-height: @btn-height-base;
    margin-top: 0 !important;
    & > label {
      color: @info-text-color;
    }
  }

  .ant-form-item-control-wrapper {
    display: inline-block;
    width: calc(100% - 60px);
  }
}

.tm-search-form-separate {
  &-inline {
    padding: 0 @padding-xs !important;

    .ant-form-item {
      margin-bottom: 0;
    }

    border-bottom: @border-width-base @border-style-base @border-color-base;
  }

  &-horizontal {
    .ant-form-item {
      margin-bottom: 0;
      padding: 0 @padding-xs;

      &:not(:last-child) {
        border-bottom: @border-width-base @border-style-base @border-color-base;
      }
    }

    &-all {
      border-top: @border-width-base @border-style-base @border-color-base;
      border-left: @border-width-base @border-style-base @border-color-base;
      border-right: @border-width-base @border-style-base @border-color-base;

      .ant-form-item {
        margin-bottom: 0;
        // padding: 0 @padding-xs;
        border-bottom: @border-width-base @border-style-base @border-color-base;
        .ant-col {
          margin-top: 0 !important;
        }
        .ant-form-item-label {
          // border-right: @border-width-base @border-style-base @border-color-base;
          // background: #E9E9E9;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          font-weight: 500;
          // font-weight: bold;
        }

        .ant-form-item-control {
          padding-left: @padding-xs;
        }
      }
    }
  }
}

// fix ant-tag shaking...
.ant-tag:hover {
  opacity: 1 !important;
}

// fix ant-card shaking...
.ant-card-grid:hover,
.ant-card-hoverable:hover {
  cursor: pointer;
  box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8,
    1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
}

// divider
.ant-divider-horizontal {
  &.divider-xxs {
    margin: @padding-xs / 2 0;
  }

  &.divider-xs {
    margin: @padding-xs 0;
  }

  &.divider-sm {
    margin: @padding-sm 0;
  }

  &.divider-md {
    margin: @padding-md 0;
  }

  &.divider-lg {
    margin: @padding-lg 0;
  }
}

.tm-ant-tabs-tab {
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  line-height: @btn-height-base;

  a {
    color: @text-color;
  }

  &.ant-tabs-tab-active {
    color: @primary-color;
    font-weight: bolder;
  }

  &:not(:first-of-type) {
    padding-left: @padding-xs;
  }

  &:not(:last-of-type) {
    padding-right: @padding-xs;
  }
}

// table-tip
.table-item-tip {
  .ant-tooltip-inner {
    color: @error-color;
    background: @white;
    font-size: 13px;
  }
  .ant-tooltip-arrow {
    border-right-color: @white;
    border-bottom-color: @white;
    &:before {
      background: @white;
    }
  }
  .ant-tooltip-arrow-content {
    background: @white;
  }
}

.table-desc-tip {
  .ant-tooltip-inner {
    color: rgba(0, 0, 0, 0.8);
    background: @white;
  }

  .ant-tooltip-arrow {
    border-right-color: @white;
    border-bottom-color: @white;

    &:before {
      background: @white;
    }
  }
}

//加车时加车tip在Modal下方
.custom-order-tip {
  z-index: 9999999;
}

// red button
.special-color {
  background: @error-color;
  color: @white;
  border-color: @error-color;
}

.special-submit-btn {
  .special-color;

  &:hover {
    .special-color;
  }

  &:active {
    .special-color;
  }

  &:focus {
    .special-color;
  }
}

.auth-modal-zIndex {
  z-index: @zindex-tooltip + 2;
}

.auth-modal {
  .auth-modal-zIndex;

  .ant-modal-header {
    background-color: @btn-info-color;
  }

  .ant-modal-close-icon,
  .ant-modal-title {
    color: @white;
  }

  .ant-modal-body {
    padding: @modal-body-padding @modal-body-padding 0;
  }
}

.sign-modal {
  .auth-modal-zIndex;

  .ant-modal-header {
    .ant-modal-title {
      color: @text-color;
      font-weight: bold;
    }
  }

  .ant-modal-footer {
    text-align: center;
  }
}

.suvery-modal {
  .auth-modal-zIndex;

  .ant-modal {
    width: 900px !important;
  }

  .ant-modal-content {
    background: transparent;
    box-shadow: none;

    .ant-modal-close {
      width: 120px;
      top: 30px;
      right: -12px;
      &-x {
        width: 100%;
      }

      .ant-statistic {
        display: inline-block;
        border: 1px solid @white;
        border-left: none;
        border-top-right-radius: @padding-xs;
        border-bottom-right-radius: @padding-xs;
        vertical-align: super;
        margin-left: -@padding-xs / 2;
        padding: 0 @padding-xs;
        background: @white;

        &-content {
          color: @info-text-color;
        }
      }
    }
  }
}

// .default-step {
//   .ant-steps-item {
//     .ant-steps-item-container {
//       .ant-steps-item-tail {
//         padding: 7.5px 24px;
//       }
//       .ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
//         margin-left: 0;
//       }
//     }
//   }
// }

// description style
.ant-descriptions {
  .ant-descriptions-row {
    .ant-descriptions-item-label {
      color: @info-text-color;
    }
  }
}

// form-item-label color
.ant-form {
  &-item {
    &-label {
      label {
        color: @info-text-color;
      }
    }
  }
}

.ant-card {
  .ant-card-head-title {
    color: @info-text-color;
  }
}

.b2b-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: @text-color-secondary;

  .ant-spin {
    .ant-spin-dot {
      font-size: @font-size-lg * 4;

      i {
        width: @font-size-lg;
        height: @font-size-lg;
      }
    }

    .ant-spin-dot-item {
      background-color: @white;
    }
  }
}

.ant-modal-confirm-body {
  .ant-modal-confirm-title {
    color: @text-color;
  }
}

//增值服务tag样式
.value-add-service {
  background: #1373c3;
  color: @white;
  width: 120px;
  white-space: normal;
  border: none;
  border-radius: 0px;
  margin-bottom: 1px;
}

.text-secondary {
  color: @info-text-color;
}

// 首页导航二级菜单
.header-nav-wrapper-sub {
  .ant-menu-vertical.ant-menu-sub {
    min-width: 80px;
  }

  .ant-menu-vertical > .ant-menu-item {
    height: @btn-height-base;
    line-height: @btn-height-base;
  }

  .ant-dropdown-menu-item {
    padding: 0 @padding-sm;
    height: @btn-height-base;
    line-height: @btn-height-base;

    a {
      margin: 0;
      padding: 0;
      color: @text-color;
      // font-weight: bolder;
    }
  }
}

.cart-tip {
  .ant-tooltip-inner {
    color: @error-color;
    background: @white;
  }

  .ant-tooltip-arrow {
    border-right-color: @white;
    border-bottom-color: @white;

    &:before {
      background: @white;
    }
  }
}

.drawing-modal {
  .ant-modal {
    top: @padding-lg;
    padding-bottom: @padding-sm;
  }

  .ant-modal-body {
    padding: @padding-xs + @padding-xs / 2;

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      padding: @padding-xs / 2 @padding-xs + @padding-xs / 2;
    }
  }
}

// 中间过渡组件的样式
.save-middle-component {
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding-bottom: @padding-lg;
  text-align: center;
  padding-top: @padding-lg * 4;
}

//物料号和收藏夹tab样式
.custom-tab {
  // .ant-tabs-card-bar .ant-tabs-ink-bar {
  //   visibility: visible;
  // }
  .ant-tabs-nav {
    margin: 0 !important;
  }
  .ant-tabs-nav .ant-tabs-nav-wrap {
    background: #fafafa;
    height: 40px;
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    margin-right: 0;
    border: none;
    padding: 12px 16px !important;
  }
  .ant-tabs-tab-active {
    background-color: #fff;
  }
}

.ant-item-cart,
.ant-item-delete {
  font-size: 1.25em;
  color: @table-color;
}

.ant-popconfirm {
  z-index: 1040;
}
// divider
.ant-divider-horizontal {
  &.divider-xxs {
    margin: @padding-xs / 2 0;
  }
  &.divider-xs {
    margin: @padding-xs 0;
  }
  &.divider-sm {
    margin: @padding-sm 0;
  }
  &.divider-md {
    margin: @padding-md 0;
  }
  &.divider-lg {
    margin: @padding-lg 0;
  }
}
.ant-divider-vertical {
  &.divider-xxs {
    margin: 0 @padding-xs / 2;
  }
  &.divider-xs {
    margin: 0 @padding-xs;
  }
  &.divider-sm {
    margin: 0 @padding-sm;
  }
  &.divider-md {
    margin: 0 @padding-md;
  }
  &.divider-lg {
    margin: 0 @padding-lg;
  }
}

//满减活动备注弹框
.remark-tip {
  .remark-main {
    margin-top: @padding-xs / 2;
    white-space: pre-wrap;
    color: rgba(0, 0, 0, 0.65);
  }
}
