@import '@/style/variable.less';

.quotationModal {
  .ant-modal-header {
    background: #ececec;
    padding: 10px 20px;

    .ant-modal-title {
      font-size: 14px;
    }
  }

  .ant-modal-close-x {
    height: 43px;
    line-height: 43px;
  }

  .quotationModal-footerBtn{
    .btn-right{
      text-align: right;
    }
  }
  .gotoHistory {
    position: absolute;
    left: 25px;
    bottom: 10px;
    font-size: 12px;
    text-decoration: underline;
    color: #4c9ee3;
    cursor: pointer;
  }
}

.quotationItem {
  margin-bottom: 0;
}

@primary-color: #0268b7;