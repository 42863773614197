// 有需要覆盖的变量，去这里面找
// @import '../../node_modules/antd/lib/style/themes/compact.less';
@import '~antd/lib/style/themes/default.less';

@primary-color: #3394fe;
@layout-header-background: #000;
@layout-header-height: 30px;
@layout-header-padding: 0;
@font-size-base: 14px;
@tree-title-height: 32px;
@font-family: 'Microsoft YaHei UI', 'Microsoft YaHei', 'Helvetica Neue',
  Helvetica, Arial, sans-serif;

.ant-statistic-content {
  font-size: unset;
}

.ant-pro-list .ant-pro-table-alert {
  display: none !important;
}

.ant-modal-mask {
  z-index: 999;
}
.ant-modal-footer {
  border-top: none;
  text-align: center;
  // height: 80px;
  .ant-btn {
    width: 130px;
    height: 35px;
    font-size: 14px;
  }
}
.ant-modal-body {
  padding-bottom: 20px;
}

.ant-modal-wrap {
  z-index: 1000;
}
