.itemPrice {
  span {
    white-space: nowrap;
  }

  display: flex;
  padding: 10%;
  padding-bottom: 0;
  color: #f04844;
  align-items: center;
  justify-content: space-around;
}

.itemTable {

  .ant-table-bordered .ant-table-header>table,
  .ant-table-bordered .ant-table-body>table,
  .ant-table-bordered .ant-table-fixed-left table,
  .ant-table-bordered .ant-table-fixed-right table {
    border-left: none;
  }

  .ant-table-bordered {
    .ant-table-thead>tr>th:last-child {
      border-right: none;
    }

    .ant-table-tbody>tr>td:last-child {
      border-right: none;
    }
  }

  .ant-table-tbody>tr:last-child>td {
    border-bottom: none;
  }

}
@primary-color: #0268b7;