@import '~antd/lib/style/themes/default.less';

.whitespace {
  &-xxs {
    height: @padding-xs / 2; // @padding-xs / 2;
  }
  &-xs {
    height: @padding-xs;
  }
  &-sm {
    height: @padding-sm; // @padding-xs * 1.5;
  }
  &-md {
    height: @padding-md; // @padding-xs * 2;
  }
  &-lg {
    height: @padding-lg; // @padding-xs * 3;
  }
  &-xl {
    height: @padding-xs * 4;
  }
}

@primary-color: #0268b7;