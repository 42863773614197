.el-component {
  &-ElExport {
    &-downloadBtn {
      &.manual {
        padding: 4px;
      }
    }
  }
}

@primary-color: #0268b7;