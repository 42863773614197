@import '@/style/variable.less';
@import '@/style/biz.less';
@import '@/style/normal.less';
@base-size: 8px;
@cateDropDown-width: 240px;
@menu-item-wrapper: @base-size * 2;
@card-content-width: 100px * 1.8;
@card-hover-width: 60px;
@right-wrapper-padding: 70px;
.topBar {
  &-wrapper {
    background-color: #0268b7;
    // height: 30px;
    // line-height: 32px;
  }
  height: @layout-header-height;
  line-height: @layout-header-height;
  color: @disabled-color;
  background-color: #0268b7;
  // height: 30px;
  // line-height: 32px;
  font-size: 13px;
  i {
    margin-right: 4px;
  }
  .aside {
    float: right;
  }
  .left-wrapper {
    .ant-typography {
      color: #fff;
    }
    float: left;
  }
  .right-wrapper {
    display: flex;
    float: right;
    align-items: center;
    a {
      color: #fff;
    }
    .ant-typography {
      color: #fff !important;
    }
  }
}

.header-section {
  // height: 19vh;
  border-bottom: 1px solid #0268b7;
}

.header-wrapper {
  height: 140px;
  .header-top-wrapper {
    width: 100%;
    position: relative;
    padding-top: 4px; // 后期如果有其它元素加入可以自行做调整
    .header-logo {
      position: absolute;
      object-fit: contain;
      top: 24px;
      // left: @main-constants-padding;
      width: @logo-width;
      height: @logo-height;
    }
    .middle-wrapper {
      position: relative;
      margin: 0 0 0 (@logo-width + @base-size * 2);
      padding-right: @logo-width + @main-constants-padding;
      margin-top: 24px;
      // 搜索区域
      .header-search-wrapper {
        margin: @base-size / 2 auto 0;
        width: calc(100% - @logo-width / 2);
        max-width: @main-constants-width - @menu-width - @cart-width -
          @main-constants-padding * 4;
        max-width: 740px;
        // height: @header-content-height;
        .ant-dropdown-trigger span:first-child {
          display: inline-block;
          width: 100%;
        }
        .input-search {
          border-left: none !important;
        }
        .ant-input-search {
          .ant-input {
            border: 1px solid @primary-color;
          }
        }
        .ant-typography.quick-link-text {
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
    // 热词搜索
    .hotwords {
      margin: 2px 0 0 292px;
      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #999;
        font: 12px/1.5 Microsoft YaHei, Heiti SC, tahoma, arial,
          Hiragino Sans GB, '\5B8B\4F53', sans-serif;
        -webkit-font-smoothing: antialiased;
      }
    }
    // 购物车
    .header-cart {
      position: absolute;
      // top: @logo-margin-top + @btn-height-base / 4;
      // right: @main-constants-padding + @logo-width * .75;
      right: 0;
      top: 28px;
      width: @menu-width;
      // height: @btn-height-base - @padding-xs / 2;
      color: @alert-error-border-color;
      border-radius: @border-radius-base;
      // border-top-left-radius: @border-radius-base;
      // border-top-right-radius: @border-radius-base;
      display: flex;
      // flex-direction: column;
      justify-content: center;

      .header-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 4px;
        width: 120px;
        height: 36px;
        // margin-left: 10px;
        white-space: nowrap;
        span {
          font-size: 13px;
          display: inline-block;
          margin-left: 4px;
          color: #4f4f4f;
          height: 24px;
          line-height: 24px;
        }
        .shopNum {
          color: #ff1a1a;
        }
        .msg-color {
          color: #0268b7;
        }
      }
      .header-special-item {
        flex-direction: row;
        width: 50%;
        white-space: nowrap;
      }
      &:hover {
        // background-color: @alert-error-bg-color;
        border-color: @alert-error-border-color;
        color: @error-color;
      }
      &:focus {
        border-color: @alert-error-border-color;
      }
    }
  }
  .cateDropDown {
    position: absolute;
    z-index: 666;
    .dropDownTrigger {
      width: @cateDropDown-width;
      height: 35px;
      line-height: 35px;
      text-align: left;
      padding: 0 20px;
      border-radius: 5px 5px 0 0;
      color: #fff;
      background-color: #0268b7;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .switch-border {
        width: 72px;
        height: 24px;
        background: #0368b7;
        border: 0.5px solid rgba(255, 255, 255, 0.6);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .switch-title {
          margin: 0 4px;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }
    .dropDown::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .dropDown {
      // height: 500px;
      overflow-x: hidden;
      overflow-y: scroll;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      overflow-x: hidden;
      overflow-y: auto;
      .dropDownMenu {
        width: @cateDropDown-width;
        display: inline-block;
        height: 100%;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        position: relative;
        ul {
          padding: 0 !important;
          text-align: left;
          list-style: none;
          color: #fff;
          li {
            position: relative;
            padding: 4px 6px 4px 20px;
            &:hover {
              cursor: pointer;
            }
          }
          .activeLi {
            background-color: #fff;
            color: #005abf;
          }
        }
      }
      .ant-spin {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        .ant-spin-dot-item {
          background-color: #fff;
        }
      }
      .overlayPanel {
        // height: 500px;
        overflow-y: auto;
        .sub-title-wrapper {
          position: relative;
          height: @btn-height-base;
          margin: 10px 0;
          border-bottom: @border-width-base @border-style-base
            @border-color-base;
          .sub-title {
            cursor: pointer;
            position: relative;
            display: inline-block;
            padding-left: @base-size * 3;
            padding-right: @base-size * 3;
            height: @btn-height-base;
            line-height: @btn-height-base - 2px;
            background-color: @background-color-base;
            color: @text-color;
            border: @border-width-base @border-style-base @border-color-base;
            &-active {
              background-color: white;
              color: @primary-color;
              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 2px;
                background-color: @primary-color;
              }
              &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -1px;
                width: 100%;
                height: 1px;
                background-color: white;
              }
            }
            & + .sub-title {
              border-left: none;
            }
          }
        }
        .overLayContent {
          .ant-popover-inner {
            padding: 0 !important;
          }
          .overLayContent-title {
            text-align: right;
            overflow: hidden;
            cursor: pointer;
            white-space: nowrap;
            font-weight: 550;
            font-size: 14px;
            text-overflow: ellipsis;
            span {
              font-size: 14px;
              &:hover {
                color: @primary-color;
                text-decoration: underline;
              }
            }
          }
          .overLayContent-item {
            font-size: 14px;
            margin-right: 18px;
            cursor: pointer;
            &:hover {
              color: @primary-color;
              text-decoration: underline;
            }
          }
          .content-row {
            position: relative;
            padding-bottom: @base-size;
            line-height: @btn-height-base / 2;
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: @card-content-width;
              right: 24px + @base-size;
              border-bottom: @border-width-base dashed @border-color-base;
            }
            &-label {
              display: inline-block;
              padding-top: @base-size;
              padding-right: @base-size * 1.5;
              width: @card-content-width;
              color: fade(@black, 85%);
              font-weight: bolder;
              vertical-align: top;
              text-align: right;
              &:hover {
                color: @info-color;
                text-decoration: underline;
                cursor: pointer;
              }
            }
            &-content {
              display: inline-block;
              width: calc(
                100% - 24px - @base-size * 2 - @card-content-width - 2px
              );
              p {
                margin: 0 !important;
              }
            }
            .sub-content-tag {
              position: relative;
              display: inline-block;
              padding: @base-size @base-size * 3 @base-size 0;
              // margin-right: @base-size;
              // margin-bottom: @base-size;
              line-height: @btn-height-base / 2;
              &:hover {
                .ant-typography {
                  color: @info-color;
                  text-decoration: underline;
                  cursor: pointer;
                }
              }
              .tag-img {
                visibility: hidden;
                position: absolute;
                left: 0;
                min-width: @card-hover-width;
                // height: @card-hover-width;
                padding: @base-size / 2 @base-size;
                border: @border-width-base solid @border-color-base;
                background-color: white;
                text-align: center;
                z-index: 101;
                .img-title {
                  color: @text-color;
                  // font-size: 0.8em;
                  margin-bottom: @base-size / 2;
                  white-space: nowrap;
                }
                img {
                  width: @card-hover-width * 2 - @base-size * 2 -
                    @border-width-base * 2;
                  height: 100%;
                }
                &-bottom {
                  visibility: visible;
                  top: @btn-height-base / 2 * 2;
                  &::after {
                    content: ' ';
                    width: @base-size;
                    height: @base-size;
                    background: white;
                    display: block;
                    position: absolute;
                    top: -@base-size / 2;
                    left: 30%;
                    margin-left: -@base-size / 2;
                    transform: rotate(45deg);
                    border-left: @border-width-base @border-style-base
                      @border-color-base;
                    border-top: @border-width-base @border-style-base
                      @border-color-base;
                  }
                }
                &-top {
                  visibility: visible;
                  bottom: @btn-height-base / 2 * 2;
                  &::after {
                    content: ' ';
                    width: @base-size;
                    height: @base-size;
                    background: white;
                    display: block;
                    position: absolute;
                    bottom: -@base-size / 2;
                    left: 30%;
                    margin-left: -@base-size / 2;
                    transform: rotate(225deg);
                    border-left: @border-width-base @border-style-base
                      @border-color-base;
                    border-top: @border-width-base @border-style-base
                      @border-color-base;
                  }
                }
              }
            }
          }
        }
        display: inline-block;
        width: 1200px-@cateDropDown-width;
        // min-height: 500px;
        background-color: #fff;
        position: absolute;
        z-index: 100000;
        padding: 0 15px;
        border-radius: 2px;
        box-sizing: border-box;
        border-color: transparent;
        box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f,
          0 5px 12px 4px #00000017;
      }
    }
    .dropDown::scrollbar {
      display: hidden;
      width: 0;
      height: 0;
    }
  }
  .header-bottom-wrapper {
    height: 35px;
    width: 100%;
    position: absolute;
    bottom: 0;
    // display: flex;
    // align-items: center;
    .header-nav-wrapper {
      position: absolute;
      left: @menu-width + @main-constants-padding;
      right: @menu-width + @main-constants-padding;
      width: 910px;
      .ant-menu-item,
      .ant-menu-submenu-title {
        padding-left: @padding-sm;
        padding-right: @padding-sm;
      }
      .ant-tabs-nav {
        margin-bottom: 0;
      }
      .ant-tabs-top > .ant-tabs-nav::before {
        border-bottom: none !important;
      }
      .ant-tabs-tab {
        .tm-ant-tabs-tab;
        .emphasize-font-size;
        &:not(:first-of-type) {
          padding-left: @padding-xs;
        }
        &:not(:last-of-type) {
          padding-right: @padding-sm;
        }
        a {
          font-weight: 700;
        }
      }
      .ant-tabs-tab-active {
        a {
          color: #0268b7;
        }
      }
      .ant-tabs-ink-bar {
        display: none !important;
      }
    }
    .super-service {
      position: absolute;
      right: 0px;
      bottom: 4px;
      min-width: @menu-width;
      text-align: center;
      .special-title {
        height: 23px;
        line-height: 19px;
        color: rgba(64, 149, 229, 1);
        font-size: 13px;
      }
    }
  }
}

.header-divider {
  top: 0; // fix .ant-divider-vertical style. for this horizontal
  margin: 0;
  border: @divider-border;
  // background-color: @primary-color;
}

.ucenter-menu {
  text-align: center;
  .ant-dropdown-menu-item a {
    font-size: 12px;
  }
  .ant-badge-multiple-words {
    padding: 0 2px;
  }
}

.input-search {
  border-left: 0.5px solid #0268b7;
}

@primary-color: #0268b7;