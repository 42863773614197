.searchTree {
  padding: 8px 6px;
  &-input {
    margin-bottom: 8px;
  }
  &-tree {
    &-searchValue {
      background-color: #3b58a3;
      color: #fff;
    }
    .ant-tree-treenode-selected::before {
      background-color: #fff !important;
    }

    .ant-tree-node-selected {
      color: #fff !important;
      background-color: #2a4791 !important;
    }

    .ant-tree-switcher {
      color: #000 !important;
    }
  }
  &-empty {
    padding-top: 20vh;
  }
}

@primary-color: #0268b7;