.color-picker-button {
  height: 28px;
  width: 40px;
  padding: 1px;
}
.color-picker-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  svg {
    height: 100%;
    width: 100%;
  }
}
.color-picker-color-show {
  background-color: #ff4d4f;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  display: inline-block;
}

@primary-color: #0268b7;