.prod-list {
  .ant-list-item:last-child {
    border-bottom: 1px solid #e4e4e4;
  }
  .ant-list-item {
    border: 1px solid #e4e4e4; // todo 边框色
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prod-img {
    width: 80px;
    height: 80px;
    min-width: 80px;
    max-height: 80px;
  }
  .prod-desc {
    flex: 1;
    margin-left: 20px;
    .status {
      display: inline-block;
      padding: 0px 10px;
      border: 1px solid #f39770;
      color: #f39770;
      border-radius: 20px;
    }
  }
  .prod-btns {
    a {
      margin: 0 5px;
      font-size: 13px;
    }
  }
}

@primary-color: #0268b7;