.ant-popover-title {
  span {
    font-size: 12px;
  }
  .columnSetting-confirm {
    font-size: 14px;
    margin-left: 4px;
    font-weight: bold;
  }
  .columnSetting-reset {
    font-size: 14px;
    margin-left: 4px;
    font-weight: bold;
  }
}
.columnSetting {
  span {
    font-size: 12px;
  }
}

@primary-color: #0268b7;